import React, { useContext, useEffect } from "react"
import { Link } from "gatsby"

import LayoutContext from "../components/LayoutContext"
import HelmetHead from "../components/HelmetHead"

const NotFoundPage = () => {
  const [layout, setLayout] = useContext(LayoutContext)

  useEffect(() => {
    setLayout(state => ({
      ...state,
      header: {
        fixed: false,
        bgColor: "white",
        color: "light",
      },
    }))
  }, [])
  return (
    <>
      <HelmetHead title="404 - Nicht gefunden" />
      <section className="section-border border-primary">
        <div className="container d-flex flex-column">
          <div className="row align-items-center justify-content-center g-0 min-vh-100">
            <div className="col-12 col-md-6 py-8 py-md-11">
              <h1 className="display-3 fw-bold text-center">Ups!</h1>
              <h2 className="mb-5 text-center text-muted">
                Diese Seite wurde nicht gefunden.
              </h2>
              <div className="text-center">
                <Link className="btn btn-primary" to="/de/">
                  Zurück zur Maklerzentrale
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default NotFoundPage
